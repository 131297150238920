import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "sales/outbound-page-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-13 pt-lg-20 pb-12 pb-lg-20 bg-default-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="11" lg="6" data-aos="fade-right" data-aos-duration="1200">
              <div className="content-img position-relative mb-11 mb-lg-0">
                <Img
                  className="w-100 w-sm-auto rounded-10 shadow-8 position-relative gr-z-index-1"
                  alt="Features"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col xs="11" lg="6" xl="5" className="pl-lg-15">
              <div className="content-widget">
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Easily Qualify Leads
                      </h3>
                      <p className="gr-text-9 mb-0">
                        Asking questions throughout their demo seems like a conversation, but more importantly gathers information to qualify and convert them later.
                      </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Connect with CRM
                      </h3>
                      <p className="gr-text-9 mb-0">
                        All interactions are stored with us and additionally can be synced with your CRM or other marketing software.
                      </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Automate Product Demos
                      </h3>
                      <p className="gr-text-9 mb-0">
                        Leverage video content to engage your prospects with an interactive demo of your product with a chatbot leading the conversation.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Features;