import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import formToInstantDemo from '../../assets/image/marketing/form-to-instant-demo.gif'

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section bg-default-6 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              sm="10"
              lg="6"
              className="offset-lg-1 order-lg-2 mb-9 mb-lg-0"
            >
              <img
                className="gr-image-box-shadow mr-lg-9 rounded-10 mw-100"
                style={{border: '8px solid #161C2D'}}
                alt="Image 1"
                src={formToInstantDemo}
              />
            </Col>
            <Col sm="10" lg="5" className="order-lg-1">
              <div className="content-text">
                <h2 className="gr-text-4 mb-7">
                  Optimize the Post-Form Experience
                </h2>
                <p className="gr-text-8 mb-0">
                  Instant Demo can replace your existing “Request a Demo” CTA, or it can be used following a Form Submission. By adding an interactive product demo you can dramatically increase conversions, because over 50% of buyers choose the first vendor that reaches out to them. Engage them on your website as opposed to waiting for a sales rep to make a call. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
