import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "react-bootstrap";

const Hero = ({onClickInstantDemo}) => {
  return (
    <div className="hero-area position-relative pt-25 pt-lg-31 pb-15 pb-lg-27 bg-default-6">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col className="col-xl-8 col-lg-9 col-md-11">
            <div className="hero-content text-center">
              <h4 className="pre-title gr-text-12 text-uppercase mb-9">
                INSTANT DEMO FOR MARKETING
              </h4>
              <h1 className="title gr-text-2 mb-8">
                Change the way your customers “Request a Demo” forever
              </h1>
              <p className="gr-text-8 mb-10 px-lg-11 px-xl-15">
                Give your inbound leads a live product demo right when they ask for it. Qualify your prospects and engage them deeply right from the start.
              </p>
              <div className="hero-btn mb-8">
                <Button variant="primary" className="with-icon gr-hover-y mb-6 mb-md-0 mr-md-7" onClick={onClickInstantDemo}>
                  Instant Demo
                  <i className="fas fa-arrow-right gr-text-11"></i>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  onClickInstantDemo: PropTypes.func
}

Hero.defaultProps = {
  onClickInstantDemo: () => {}
}

export default Hero;