import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "marketing/qualified-leads.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section bg-default-6 pb-13 pb-lg-25">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="7">
              <div
                className="content-img mr-lg-9 mb-9 mb-lg-0"
              >
                <Img
                  className="main-img w-100"
                  alt="Image 1"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="col-sm-10 col-lg-5">
              <div className="content-text pl-xl-7">
                <h2 className="gr-text-4 mb-7">
                  Create the most Qualified Leads ever
                </h2>
                <p className="gr-text-8 mb-0">
                  Fill out your CRM with real qualified leads by providing value to your prospects, and gaining valuable information from them in return. Video is more engaging than a Blog Post, Social Post, or Email campaign. Start using content that drives deeper engagement. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
