import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col, Button } from "react-bootstrap";

const SpeedToLead = () => {
  const data = useStaticQuery(graphql`
    query {
      contentImage: file(relativePath: { eq: "l1/png/hbr-chart.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 bg-default-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="6" className="pr-lg-9">
              <div className="content-img img-group-1">
                <Img
                  className="img-fluid"
                  fluid={data.contentImage.childImageSharp.fluid}
                  alt="Lead to Respond"
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="offset-lg-1 mt-10 mt-lg-0">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  How fast do you respond to inbound leads?
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  Measure your inbound process with our free to use Speed to Lead tool
                </p>
                <div className="content-btn">
                  <Button variant="primary" className="with-icon gr-hover-y" target="_blank" href="https://app.instantdemo.co/speed-to-lead">
                    Measure Now
                    <i className="fas fa-arrow-right gr-text-11"></i>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SpeedToLead;
