import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/marketing/Hero";
import Features from "../sections/marketing/Features";
import Content1 from "../sections/marketing/Content1";
import Content2 from "../sections/marketing/Content2";
import Content3 from "../sections/marketing/Content3";
import SpeedToLead from "../sections/marketing/SpeedToLead";
import Content4 from "../sections/marketing/Content4";

const Marketing = () => {
  const onClickInstantDemo = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_flow('e1d47f39-1e3f-45b4-8948-b2eb2ec14dfe')
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Hero onClickInstantDemo={onClickInstantDemo} />
        <Features />
        <Content1 />
        <Content2 />
        <Content3 />
        <SpeedToLead />
        <Content4 />
      </PageWrapper>
    </>
  );
};

export default Marketing;